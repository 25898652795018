.faq {
    /* width: 100%; */
    margin: 0 auto;
    /* background-color: #111; */
    color: white;
    border-radius: 10px;
    padding: 20px;
}

.heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.faqItem {
    background-color: #192028;
    margin: 1rem 0;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.faqQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
}

.faqToggle {
    font-size: 1.5rem;
    color: #f2c94c;
    display: inline-block;
    transition: transform 0.3s ease; /* Smooth rotation */
}
.faqToggle.open {
    font-size: 1.8rem;
}
.open {
    transform: rotate(180deg); /* Rotate to change from + to - */
}

.faqAnswerWrapper {
    overflow: hidden;
    transition: height 0.3s ease; /* Smooth height transition */
}

.faqAnswer {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 1.5;
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth opacity transition */
}

.faqAnswerWrapper[style*='height: 0px'] .faqAnswer {
    opacity: 0; /* Hide when closed */
}

.faqAnswerWrapper[style*='height:'] .faqAnswer {
    opacity: 1; /* Show when open */
}
@media (max-width: 768px) {
    .faq {
        padding: 20px 0px;
    }
}
