@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}
.container {
    width: 90%;
    margin: auto;
}
a {
    text-decoration: unset;
}
body {
    margin: 0px;
    background-color: black;
}
.container {
    width: 1400px;
    margin: auto;
}

.subContainer {
    width: 1000px;
    margin: auto;
}
.swiperWrapper {
    overflow: hidden;
}
.swiperWrapper .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    display: flex;
    align-items: center;
    width: 100%;
    transition-timing-function: linear !important;
}
.swiperWrapper img {
    width: 100%;
}
.swiperWrapper a {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
}
.swiperWrapper .swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: -webkit-transform;
    transition-property: transform;
}
.rubik {
    position: absolute;
    left: -150px;
    top: 100px;
}
@media (max-width: 768px) {
    .lottieContainer {
        width: 300px !important;
        height: 300px !important;
    }
    .rubik {
        z-index: -1;
    }
    .container,
    .subContainer {
        width: 100%;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
