.swiperDis {
    /* width: 20%; */
    display: flex;
    /* align-items: center; */
}
.features h2 {
    color: white;
    text-align: center;
    font-size: 32px;
}
.swiperDis img {
    filter: brightness(0) saturate(100%) invert(100%);
}
