.homeBanner span {
    color: white;
    letter-spacing: 6px;
    font-weight: 100;
    display: block;
}
.callUs {
    background: url('../../../public/assets/images/patt.jpg') repeat center
        center fixed;
    background-repeat: repeat;
    background-size: contain;
    background-attachment: fixed;
    background-color: #edc348; /* Fallback color */
    padding: 100px 0;
    text-align: center;
    position: relative;
    color: white;
    font-family: Arial, sans-serif;

    animation: moveBackground 20s linear infinite;
}
@keyframes moveBackground {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}
.callUs::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 40%,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.8) 100%
    );
    /* z-index: 1; */
}
.callUs div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    position: relative;
}
.callUs h2 {
    font-size: 42px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}
.callUs a {
    background-color: white;
    border: none;
    padding: 15px 30px;
    /* cursor: pointer; */
    border-radius: 5px;
    font-size: 18px;
    width: 200px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    color: #0b1216;
}
.callUs a:hover {
    transition: 0.3s;
    background-color: #fff645;
}
@keyframes moveGradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.homeBanner h1 {
    position: relative;
    z-index: 2;
    font-size: 120px;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(
        to right,
        #88d8ff,
        #ffef7b,
        #ff7b7b,
        #ffef7b,
        #88d8ff
    );
    background-size: 400% 400%;
    -webkit-background-clip: text;
    color: transparent;
    animation: moveGradient 7s ease infinite;
    margin: 0px;
}
.homeBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 99;
}
.homeBanner p {
    color: white;
    margin: 0px;
    width: 60%;
    margin: auto;
    text-align: center;
    padding: 20px 0px;

    font-size: 18px;
    font-weight: 400;
    line-height: 1.75;
}

.mainLogos {
    width: 300px;

    margin: auto;
    margin-bottom: 10px;
}
.homeBck {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    overflow: hidden; /* Prevent overflow */
}

.homeBck::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../public/assets/images/bck.svg');

    background-size: cover; /* Adjust the background image sizing */
    background-position: center; /* Position the background image */
    opacity: 0.13; /* Set the desired opacity */
}
@keyframes moveUp {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 0 -40px; /* Moves up by one grid unit */
    }
}

.homeButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.homeButtons a {
    border: 1px solid white;
    width: 150px;
    padding: 10px;
    border-radius: 50px;
    text-align: center;
    color: white;
}
@media (max-width: 768px) {
    .homeBanner {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        z-index: 2;
        position: relative;
    }
    .homeBanner p {
        width: 100%;
    }
    .mainLogos {
        width: 200px;
    }
    .callUs div {
        flex-direction: column;
        align-items: center;
    }
    .callUs {
        animation: moveBackground 30s linear infinite;
    }
}
