.animationContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align lines to the top */
    height: 100%; /* Adjust this height to fit your design */
    width: 100%;
    margin: 0 20px; /* Add margins for spacing */
    position: absolute; /* Set to relative for proper positioning of child elements */
    overflow: hidden; /* Prevent lines from overflowing */
}

.line {
    width: 3px; /* Adjusted width for visibility */
    height: 200px; /* Set fixed height */
    background: linear-gradient(
        to top,
        /* Gradient from full color to transparent */ white,
        rgba(255, 179, 0, 0)
    );
    border-radius: 20px;
    opacity: 0.5;
    animation: lineAnimation 6s linear infinite; /* Changed to linear for smoother motion */
    margin: 0 10px; /* Add horizontal spacing between lines */
}

/* Adjust animation timing for a staggered effect */
.line1 {
    animation-delay: 0s;
}
.line2 {
    animation-delay: 0.3s;
}
.line3 {
    animation-delay: 0.5s;
}
.line4 {
    animation-delay: 0.7s;
}
.line5 {
    animation-delay: 0.8s;
}
.line6 {
    animation-delay: 0.9s;
}
.line7 {
    animation-delay: 1s;
}

@keyframes lineAnimation {
    0% {
        transform: translateY(-150%); /* Start at its original position */
    }
    100% {
        transform: translateY(200%); /* Move down by its height (100%) */
        opacity: 0; /* Fade out while moving */
    }
}
@media (max-width: 768px) {
    .line {
        height: 30px;
        opacity: 0.7;
        animation: lineAnimation 5s linear infinite; /* Changed to linear for smoother motion */
    }
}
