/* General Styles */
.footer {
    background-color: #0a1318;
    color: #fff;
    padding: 40px;
    text-align: left;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.leftSection {
    width: 50%;
    margin-right: 50px;
}

.logo {
    width: 150px;
    margin-bottom: 20px;
}

.description {
    font-size: 1rem;
    line-height: 1.5;
    color: #d1d1d1;
}

.description a {
    color: #f2c94c;
    text-decoration: none;
}

.description a:hover {
    text-decoration: underline;
}

.copyright {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #8e8e8e;
}

.rightSection {
    display: flex;
    gap: 40px;
    width: 40%;
}

.column {
    flex: 1;
}

.column h4 {
    color: #f2c94c;
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.column ul {
    list-style: none;
    padding: 0;
}

.column ul li {
    margin-bottom: 10px;
}

.column ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 0.95rem;
}

.column ul li a:hover {
    text-decoration: underline;
}

.bottomLinks {
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
}

.bottomLinks a {
    color: #8e8e8e;
    margin: 0 15px;
    text-decoration: none;
}

.bottomLinks a:hover {
    text-decoration: underline;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .leftSection {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
    }

    .rightSection {
        /* flex-direction: column; */
        gap: 30px;
        width: 100%;
    }

    .column ul li a {
        font-size: 1rem;
    }

    .bottomLinks {
        font-size: 1rem;
    }
}
